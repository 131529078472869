import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import * as sections from "../components/sections"
import Fallback from "../components/fallback"


export default function Projects(props) {
    const { homepage } = props.data

  return (
    <Layout {...homepage}>
      {homepage.blocks.map((block) => {
        const { id, blocktype, ...componentProps } = block
        const Component = sections[blocktype] || Fallback
        return <Component key={id} {...componentProps} />
      })}
    </Layout>
  )
}

export const query = graphql`
{
    homepage {
        id
        title
        description
        image {
            id
            url
        }
        blocks: content {
            id
            blocktype
            ...HomepageHeroContent
            ...HomepageFeatureListContent
            ...HomepageCtaContent
            ...HomepageLogoListContent
            ...HomepageTestimonialListContent
            ...HomepageBenefitListContent
            ...HomepageStatListContent
            ...HomepageProductListContent
        }
    }
}
`